exports.components = {
  "component---src-components-photos-photo-image-tsx": () => import("./../../../src/components/photos/PhotoImage.tsx" /* webpackChunkName: "component---src-components-photos-photo-image-tsx" */),
  "component---src-components-photos-photo-month-tsx": () => import("./../../../src/components/photos/PhotoMonth.tsx" /* webpackChunkName: "component---src-components-photos-photo-month-tsx" */),
  "component---src-components-photos-photo-year-tsx": () => import("./../../../src/components/photos/PhotoYear.tsx" /* webpackChunkName: "component---src-components-photos-photo-year-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photogallery-tsx": () => import("./../../../src/pages/photogallery.tsx" /* webpackChunkName: "component---src-pages-photogallery-tsx" */),
  "component---src-pages-photos-tsx": () => import("./../../../src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

